import { useEffect, useContext } from "react";

// Extending window
declare global {
    interface Window {
        [key: string]: any;
    }
}

const isSubArrayEqualArray = (
    baseArray: string[],
    subArray: string[]
): boolean => {
    return baseArray.join("").toLowerCase() == subArray.join("").toLowerCase();
};

const isSubArrayContainedInArray = (
    baseArray: string[],
    subArray: string[]
): boolean => {
    return baseArray
        .join("")
        .toLowerCase()
        .startsWith(subArray.join("").toLowerCase());
};

const keyboardListenerHandler = (
    id: string,
    pattern: string[],
    callback: () => void
) => (event: KeyboardEvent): void => {
    if (!window[id]) {
        window[id] = [];
    }
    window[id].push(event.key);

    if (isSubArrayEqualArray(pattern, window[id])) {
        callback();
        window[id] = [];
        return;
    }

    // check if valid pattern
    if (isSubArrayContainedInArray(pattern, window[id]) === false) {
        window[id] = [];
        return;
    }
};

/**
 * @param id Need to be a special id to register the event listener. You can pass uuidv4()
 * @param pattern The pattern you want to register to be listened for. For example ["alt", "1"] will listen for alt + 1
 * @param callBack Call back to be executed when the pattern is clicked
 */
const useKeyboardListener = (
    id: string,
    pattern: string[],
    callBack: () => void
) => {
    useEffect(() => {
        const functionReference = keyboardListenerHandler(
            id,
            pattern,
            callBack
        );
        window.addEventListener("keydown", functionReference);

        return function cleanUp() {
            delete window[id];
            window.removeEventListener("keydown", functionReference);
        };
    }, []);
};

export default useKeyboardListener;
