import { Box, Paper } from "@material-ui/core";
import React from "react";
import { NumbersHelper } from "../../../../services/helpers/NumbersHelper";
import { observer } from "mobx-react-lite";
import HelpIcon from "@material-ui/icons/Help";
import "./InformationBox.scss";
import { Tooltip } from "antd";

export interface DataModel {
    name: string;
    value: string | number;
    prefix?: string;
    postfix?: string;
    explanation?: string;
}

interface Props {
    title: string;
    data?: DataModel[];
    style?: React.CSSProperties;
}

const InformationBox = (props: Props) => {
    const { title, data, style } = props;

    const renderData = () => {
        if (data) {
            return data.map((res: DataModel, i: number) => {
                let finalValue = res.value;
                if(res.value === "-") {
                    finalValue = "-";
                }
                else if (typeof res.value == "number" || typeof res.value == "string") {
                    finalValue = NumbersHelper.numberWithCommas(res.value);
                    if (res.prefix) {
                        finalValue = `${res.prefix}${finalValue}`;
                    }
                    if (res.postfix) {
                        finalValue = `${finalValue}${res.postfix}`;
                    }
                }
                return (
                    <Box key={i} display="flex" flexDirection="column" className="info-box" >
                        <Box display="flex" alignItems="center" height={25}>
                            <Box
                                className="text text-gray infobox__header"
                                fontWeight="600">
                                {res.name}
                            </Box>
                            {res.explanation && (
                                <Box>
                                    <Tooltip
                                        placement="topLeft"
                                        title={res.explanation}>
                                        <HelpIcon className="help-icon" />
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                        <Box
                            className="text text-gray text-numeric infobox__content"
                            fontWeight="300"
                            mt={3}>
                            {finalValue}
                        </Box>
                    </Box>
                );
            });
        }
    };

    return (
        <Box width="fit-content" style={style} height={'100%'}>
            <Paper elevation={2} style={{height: '100%'}}>
                <Box p={4} >
                    <Box
                        component="h6"
                        className="text text-blue"
                        fontSize="16px"
                        fontWeight="700">
                        {title}
                    </Box>

                    <Box data-about="information-grid" display="flex" mt={2}>
                        {renderData()}
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default observer(InformationBox);
