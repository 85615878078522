import axios, { AxiosResponse } from "axios";
import { getEnvironment, IEnvironment } from "../config/EnvironmentUrls";
import { SessionStorageKeys } from "../constants/SessionStorageKeys";
import { FetchError } from "../errors/FetchError";
import { UnauthorizedError } from './../errors/UnauthorizedError';
import { SessionStorageService } from './SessionStorageService';

export abstract class BaseService {
    protected urls: IEnvironment;
    private relativeControllerPath: string;

    constructor(relativeControllerPath: string) {
        this.urls = getEnvironment();
        this.relativeControllerPath = relativeControllerPath;

    }

    protected async get<T>(relativeUrl: string): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.get(this.buildUrl(relativeUrl), {
                headers: { ...this.MakeHeaders() }
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }
            throw new FetchError(response);
        }
        catch (error) {
            console.error(error.response);
            if (error.response.status === 401) {
                throw new UnauthorizedError();
            }

            throw new FetchError(error.response);
        }

    }

    protected async post<T>(relativeUrl: string, payload: object, isArrayBufferResponseType: boolean = false): Promise<T> {
        const headers = {
            'Content-Type': 'application/json',
        };

        let options: any = {}
        options['headers'] = { ...headers, ...this.MakeHeaders() }
        if (isArrayBufferResponseType) {
            options['responseType'] = 'arraybuffer'
        }

        try {
            const response: AxiosResponse<T> = await axios.post(this.buildUrl(relativeUrl), payload, {
                // headers: {
                //     ...headers, ...this.MakeHeaders()
                // },
                ...options,
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw new FetchError(response);
        }
        catch (error) {
            console.error(error.response);

            if (error.response.status === 401) {
                throw new UnauthorizedError();
            }
            throw new FetchError(error.response);
        }

    }

    protected async patch<T>(relativeUrl: string, payload: object): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.patch(this.buildUrl(relativeUrl), payload, {
                headers: { ...this.MakeHeaders() }
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }

            throw new FetchError(response);
        }
        catch (error) {
            console.error(error.response);

            if (error.response.status === 401) {
                throw new UnauthorizedError();
            }
            throw new FetchError(error.response);
        }

    }

    protected async put<T>(relativeUrl: string, payload: object): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.put(this.buildUrl(relativeUrl), payload, {
                headers: { ...this.MakeHeaders() }
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }
            throw new FetchError(response);
        }
        catch (error) {
            console.error(error.response);

            if (error.response.status === 401) {
                throw new UnauthorizedError();
            }
            throw new FetchError(error.response);
        }

    }

    protected async delete<T>(relativeUrl: string): Promise<T> {
        try {
            const response: AxiosResponse<T> = await axios.delete(this.buildUrl(relativeUrl), {
                headers: { ...this.MakeHeaders() }
            });
            if (this.IsSuccessfulResponse(response)) {
                return response.data;
            }
            throw new FetchError(response);
        }

        catch (error) {
            console.error(error.response);

            if (error.response.status === 401) {
                throw new UnauthorizedError();
            }
            throw new FetchError(error.response);
        }
    }

    private buildUrl(relativeUrl: string): string {
        return `${this.urls.baseApiUrl}/${this.relativeControllerPath}${relativeUrl}`;
    }

    private IsSuccessfulResponse<T>(response: AxiosResponse<T>): boolean {
        if (response.status >= 200 && response.status <= 226) {
            return true;
        }
        return false;
    }

    /**
     * Makes headers for the application state
     */
    private MakeHeaders(): any {
        return {
            Authorization: `Bearer ${SessionStorageService.getItem<string>(SessionStorageKeys.JwtToken)}`
        }
    }
}