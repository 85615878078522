
type competitionCategory = "High" | "Medium" | "Low";

export class MapPoint {
    /**
     *
     */
    public totalMediumCompetition: number;
    public competitionCategory: competitionCategory;

    constructor(
        public location: string,
        public latitude: number,
        public longitude: number,
        public totaljobs: number,
        public totalLowCompetition: number,
        public totalHighCompetition: number,
        public totalApplicants: number
    ) {
        this.totalMediumCompetition = this.totaljobs - this.totalLowCompetition - this.totalHighCompetition;

        const maxJobsInSomeCompetitionCategory =
            Math.max(this.totalLowCompetition, this.totalMediumCompetition, this.totalHighCompetition);
        this.competitionCategory = this.getCompetitionCategory(maxJobsInSomeCompetitionCategory);
    }

    private getCompetitionCategory(maxJobsInSomeCompetitionCategory: number): competitionCategory {
        switch (maxJobsInSomeCompetitionCategory) {
            case this.totalHighCompetition:
                return "High";
            case this.totalMediumCompetition:
                return "Medium";
            case this.totalLowCompetition:
            default:
                return "Low";
        }

    }
}