export class SessionStorageService {
    public static setItem<T>(key: string, value: T) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static getItem<T>(key: string): T | "" {
        const value = window.sessionStorage.getItem(key);
        if (!value) return "";

        return (JSON.parse(value)) as T;
    }

    public static removeItem(key: string) {
        window.sessionStorage.removeItem(key);
    }
}