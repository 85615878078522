import React, { useContext, useState, useEffect } from "react";
import { Box, Button, ButtonGroup, Switch } from "@material-ui/core";
import { useStyles } from "./CampaignStrategy.styles";
import { RootStoreContext } from "../../../../stores/RootStoreContext";
import { observer } from "mobx-react-lite";
import { EStrategyName } from "../../../../models/prediction/enums/EStrategyName";
import {
    biddingStrategyType,
    jobExpansionStrategyType,
} from "../../PredictionPage";
import LayoutTopSectionColumn from "../layout-top-section-column/LayoutTopSectionColumn";
import { message } from 'antd';

interface Props {
    biddingStrategy: biddingStrategyType;
    setBiddingStrategy: (v: biddingStrategyType) => void;
    jobExpansionStrategy: jobExpansionStrategyType;
    setJobExpansionStrategy: (v: jobExpansionStrategyType) => void;
}

type strategy = "bidding" | "job expansion";
// type biddingStrategyType = "normal" | "high";
// type jobExpansionStrategyType = "with" | "without";

const CampaignStrategy = (props: Props) => {
    const [blockStrategyChange, setBlockStrategyChange] = useState(false);
    const blockChangeTimeInMilliseconds = 650;

    const {
        biddingStrategy,
        setBiddingStrategy,
        jobExpansionStrategy,
        setJobExpansionStrategy,
    } = props;
    const classes = useStyles();
    const { predictionStore } = useContext(RootStoreContext);

    const isStrategyActive = (strategy: strategy, strategyText: string) => {
        if (strategy === "bidding") {
            if (strategyText === "high") {
                return (
                    predictionStore.activeCampaignStrategy ===
                        EStrategyName.HighBid ||
                    predictionStore.activeCampaignStrategy ===
                        EStrategyName.HighBidAndExpansions
                );
            } else {
                // "normal"
                return (
                    predictionStore.activeCampaignStrategy !==
                        EStrategyName.HighBid &&
                    predictionStore.activeCampaignStrategy !==
                        EStrategyName.HighBidAndExpansions
                );
            }
        } else {
            // strategy == "job expansion"
            if (strategyText === "with") {
                return (
                    predictionStore.activeCampaignStrategy ===
                        EStrategyName.Expansions ||
                    predictionStore.activeCampaignStrategy ===
                        EStrategyName.HighBidAndExpansions
                );
            } else {
                return (
                    predictionStore.activeCampaignStrategy !==
                        EStrategyName.Expansions &&
                    predictionStore.activeCampaignStrategy !==
                        EStrategyName.HighBidAndExpansions
                );
            }
        }
    };

    const onChangeStrategy = (type: strategy, value: boolean) => (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {

        if(blockStrategyChange) {
            message.warn("Please wait half a second. You can change a strategy every 0.5 seconds")
            return;
        }
        setBlockStrategyChange(true);

        if (type == "bidding") {
            if (value && biddingStrategy === "high") return;
            if (!value && biddingStrategy === "normal") return;

            if (value) {
                setBiddingStrategy("high");
                if (jobExpansionStrategy === "yes") {
                    predictionStore.SetActiveCampaignStrategy(
                        EStrategyName.HighBidAndExpansions
                    );
                    return;
                }
                predictionStore.SetActiveCampaignStrategy(
                    EStrategyName.HighBid
                );
                return;
            }

            setBiddingStrategy("normal");
            if (jobExpansionStrategy === "yes") {
                predictionStore.SetActiveCampaignStrategy(
                    EStrategyName.Expansions
                );
                return;
            }
            predictionStore.SetActiveCampaignStrategy(EStrategyName.Normal);
        }
        if (type == "job expansion") {
            if (value && jobExpansionStrategy === "yes") return;
            if (!value && jobExpansionStrategy === "no") return;

            if (value) {
                setJobExpansionStrategy("yes");
                if (biddingStrategy === "high") {
                    predictionStore.SetActiveCampaignStrategy(
                        EStrategyName.HighBidAndExpansions
                    );
                    return;
                }
                predictionStore.SetActiveCampaignStrategy(
                    EStrategyName.Expansions
                );
                return;
            }

            setJobExpansionStrategy("no");
            if (biddingStrategy === "high") {
                predictionStore.SetActiveCampaignStrategy(
                    EStrategyName.HighBid
                );
                return;
            }
            predictionStore.SetActiveCampaignStrategy(EStrategyName.Normal);
        }
    };

    useEffect(() => {
        if(blockStrategyChange) {
            window.setTimeout(() => {
                setBlockStrategyChange(false);
            }, blockChangeTimeInMilliseconds)
        }
    }, [blockStrategyChange])

    return (
        <Box data-about="campaign-strategy" width="350px">
            <Box
                component="h6"
                className="text text-blue"
                fontSize={16}
                fontWeight="bold">
                Campaign Strategy
            </Box>

            <Box display="flex" alignItems="center" mt={3}>
                <LayoutTopSectionColumn title="Bid level">
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group">
                        <Button
                            className={
                                isStrategyActive("bidding", "normal")
                                    ? classes.buttonActive
                                    : classes.button
                            }
                            onClick={onChangeStrategy("bidding", false)}>
                            Normal
                        </Button>
                        <Button
                            className={
                                isStrategyActive("bidding", "high")
                                    ? classes.buttonActive
                                    : classes.button
                            }
                            onClick={onChangeStrategy("bidding", true)}>
                            High
                        </Button>
                    </ButtonGroup>
                </LayoutTopSectionColumn>

                <LayoutTopSectionColumn title="Job expansion" padLeft>
                    <ButtonGroup
                        color="primary"
                        aria-label="outlined primary button group">
                        <Button
                            className={
                                isStrategyActive("job expansion", "with")
                                    ? classes.buttonActive
                                    : classes.button
                            }
                            onClick={onChangeStrategy("job expansion", true)}>
                            Yes
                        </Button>
                        <Button
                            className={
                                isStrategyActive("job expansion", "without")
                                    ? classes.buttonActive
                                    : classes.button
                            }
                            onClick={onChangeStrategy("job expansion", false)}>
                            No
                        </Button>
                    </ButtonGroup>
                </LayoutTopSectionColumn>
            </Box>
        </Box>
    );
};

export default observer(CampaignStrategy);
