import { Box } from "@material-ui/core";
import React from "react";
import "./HorizontalDivider.scss";

interface Props {
    text?: string;
    noteText?: string;
}

const HorizontalDivider = (props: Props) => {
    const { text, noteText } = props;

    return (
        <Box className="horizontal-divider" display="flex" alignItems="center">
            {text && (
                <Box
                    className="text text-blue text-sub"
                    mr={1}
                    fontWeight="700">
                    {text}
                </Box>
            )}
            {noteText && (
                <Box mx={1} fontSize={12} color="#999B9D" className="text">
                    {noteText}
                </Box>
            )}
            <Box className="divider" flexGrow="1"></Box>
        </Box>
    );
};

export default HorizontalDivider;
