export interface IEnvironment {
    baseApiUrl: string;
    basePath: string;
}

class DevEnvironment implements IEnvironment {
    baseApiUrl: string = "https://ne9go5hvf0.execute-api.us-east-1.amazonaws.com/Prod/api";//"http://localhost:5500/api";
    basePath: string = "";
}

class QAEnvironment implements IEnvironment {
    baseApiUrl: string = "https://ne9go5hvf0.execute-api.us-east-1.amazonaws.com/Prod/api";
    basePath: string = "";
}

class ProdEnvironment implements IEnvironment {
    // baseApiUrl: string = "http://campaignmanager.pandoiq.com/ppr-api/api";
    baseApiUrl: string = "https://mwx9zuzttk.execute-api.us-east-1.amazonaws.com/Prod/api";
    basePath: string = "";
}


export function getEnvironment(): IEnvironment {
    switch (process.env.REACT_APP_ENV) {
        case "prod":
            return new ProdEnvironment();
        case "qa":
            return new QAEnvironment();
        case "dev":
        default:
            return new DevEnvironment();
    }
}