import { Account } from '../../models/Account';
import { BaseInputValidator } from '../../services/input-validator/BaseInputValidator';
import { NumbersHelper } from '../../services/helpers/NumbersHelper';
import { IInputValidator, severity, ValidatorResponse } from '../../services/input-validator/IInputValidator';

export interface InputType {
    account: Account | null | undefined;
    margin?: string;
    cvh?: string;
}

export class AdminPreloadRule
    extends BaseInputValidator<InputType>
    implements IInputValidator<InputType> {

    isValid(requestParams: InputType): ValidatorResponse {
        if (!requestParams.account) {
            return new ValidatorResponse(false, "error", "Please select an account");
        }

        if (!requestParams.margin) {
            return new ValidatorResponse(false, "error", "Please select a margin");
        }

        if (!requestParams.cvh) {
            return new ValidatorResponse(false, "error", "Please enter applicants to hire conversion rate");
        }

        if (NumbersHelper.isNumber(requestParams.cvh) === false) {
            return new ValidatorResponse(false, "error", "Please make sure applicants to hire conversion is a valid number");
        }

        if (
            NumbersHelper.isNumber(requestParams.margin) === false ||
            Number(requestParams.margin) < 0 ||
            Number(requestParams.margin) > 100
        ) {
            return new ValidatorResponse(false, "error", "Please make sure margin is a number between 0 and 100");
        }



        return new ValidatorResponse(true, "info", "");
    }
}