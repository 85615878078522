import { Redirect, Route, RouteProps } from "react-router";
import { useContext } from "react";
import { RootStoreContext } from "../stores/RootStoreContext";
import { Routes } from "../constants/Routes";
import { observer } from "mobx-react-lite";

export type ProtectedRouteProps = {} & RouteProps;

const AuthorizedRoute = ({ ...routeProps }: ProtectedRouteProps) => {
    const { userStore } = useContext(RootStoreContext);
    if (userStore.user) {
        return <Route {...routeProps} />;
    } else {
        return <Redirect to={{ pathname: Routes.Login }} />;
    }
};

export default observer(AuthorizedRoute);
