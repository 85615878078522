
/**
 * Predictions API routes
 */
 class Predictions {
    public PreloadAccount = "/pre-load";
    public GetPrediction = "/accountPrediction";
    public GetListOfReadyAccount = "/ready-accounts";
    public CreateExcelFromPredictions = "/create-excel";
    public AuditPredictions = "/audit-predictions";
    public PredictionBatchUpload = "/userFilter/uploadBatch";
    public CreateExcelFromBatch = "/userFilter/createExcelFromBatch";
}

/**
 * Predictions API routes
 */
 class Account {
    public GetAllAccounts = "/";
}



export class ApiRoutes {
    public static Predictions = new Predictions();
    public static Account = new Account();
}

