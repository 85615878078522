import React, { useContext, useState } from "react";
import { Box, Button, MenuItem, Select } from "@material-ui/core";
import { useStyles } from "./Settings.styles";
import { RootStoreContext } from "../../../../stores/RootStoreContext";
import moment from "moment";
import { observer } from "mobx-react-lite";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { FaPercent } from "react-icons/fa";
import Input from "../../../common/inputs/input/Input";
import LayoutTopSectionColumn from "../layout-top-section-column/LayoutTopSectionColumn";
import {
    predictionDuration,
    PredictionSettings,
} from "../../../../stores/prediction/datamodels/PredictionSettings";
import { message } from "antd";
import { SettingsRules } from "./SettingsRules";

interface Props {}

const Settings = (props: Props) => {
    /* App State
     * ################################################
     */
    const { predictionStore } = useContext(RootStoreContext);

    /* Local State
     * ################################################
     */
    const _initialCvr =
        (predictionStore.defaultPredictionSettings.cvr || 0).toFixed(2) ?? "";

    const _initialCvh =
        predictionStore.defaultPredictionSettings.cvh?.toString() ?? "";

    const [cvr, setCvr] = useState<string>(_initialCvr);
    const [cvh, setCvh] = useState<string>(_initialCvh);
    const [duration, setDuration] = useState<predictionDuration>(
        predictionStore.defaultPredictionSettings.predictionDuration
    );

    /* Variables
     * ################################################
     */
    const classes = useStyles();
    const settingsRules = new SettingsRules();

    /* Logic
     * ################################################
     */
    const handleReset = () => {
        setCvr(_initialCvr);
        setCvh(_initialCvh);
        setDuration(
            predictionStore.defaultPredictionSettings.predictionDuration
        );
        const predictionSettings = new PredictionSettings(30);
        predictionStore.SetPredictionSettings(predictionSettings);
        message.info("Restored default settings");
    };

    const handleApply = () => {
        const [isValid, severity, errorMessage] = settingsRules.isValidInput(
            cvr,
            cvh,
            duration,
            predictionStore.predictionSettings,
            predictionStore.defaultPredictionSettings
        );
        if (isValid === false) {
            message[severity](errorMessage);
            return;
        }
        const predictionSettings = new PredictionSettings(
            duration,
            cvr ? Number(cvr) : undefined,
            cvh ? Number(cvh) : undefined
        );
        predictionStore.SetPredictionSettings(predictionSettings);
        message.info("Applied settings filter succesfully");
    };

    const getClassName = (forElement: "reset-btn") => {
        let className = "";
        const appendClassName = (_className: string) => {
            className += `${_className} `;
        };

        if (forElement === "reset-btn") {
            appendClassName(classes.resetButton);
            if (!cvr && !cvh && duration === 30) {
                appendClassName("disabled");
            }
        }

        return className;
    };

    /* Render
     * ################################################
     */
    return (
        <Box data-about="settings">
            <Box
                component="h6"
                className="text text-blue"
                fontSize={16}
                fontWeight="bold">
                Settings
            </Box>

            <Box display="flex" alignItems="center" mt={3}>
                <LayoutTopSectionColumn
                    title="Duration"
                    icon={<ScheduleIcon />}>
                    <Box style={{ transform: "translateY(-5px)" }}>
                        <Select
                            className={classes.dateSelect}
                            variant="standard"
                            value={duration}
                            onChange={(e) => {
                                const _duration: predictionDuration = Number(
                                    e.target.value
                                ) as predictionDuration;
                                // predictionStore.setPredictionDuration(duration);
                                setDuration(_duration);
                            }}>
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                        </Select>
                        <Box
                            component="span"
                            className="text text-gray"
                            style={{ marginLeft: "4px" }}>
                            Days
                        </Box>
                    </Box>
                </LayoutTopSectionColumn>

                <LayoutTopSectionColumn
                    title="Conversion rates"
                    icon={<FaPercent />}
                    padLeft>
                    <Box display="flex" alignItems="center">
                        <Box width={130}>
                            <Input
                                placeholder="Click to app. %"
                                type="text"
                                value={cvr}
                                valueSetter={setCvr}
                            />
                        </Box>
                        <Box width={130} ml={3}>
                            <Input
                                placeholder="App. to hire %"
                                type="text"
                                value={cvh}
                                valueSetter={setCvh}
                            />
                        </Box>
                    </Box>
                </LayoutTopSectionColumn>

                <LayoutTopSectionColumn padLeft>
                    <Box display="flex" alignItems="center">
                        <Box width={72} height={40}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApply}>
                                Apply
                            </Button>
                        </Box>
                        <Box width={72} height={40} ml={2}>
                            <Button
                                className={getClassName("reset-btn")}
                                variant="outlined"
                                color="default"
                                onClick={handleReset}>
                                RESET
                            </Button>
                        </Box>
                    </Box>
                </LayoutTopSectionColumn>
            </Box>
        </Box>
    );
};

export default observer(Settings);
