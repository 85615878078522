import { action, makeObservable, observable } from "mobx";
import { Nullable } from "../../types/Nullable";
import { UserAuthorizationRequest } from './../../models/authorization/UserAuthorizationRequest';
import { UserAuthorizationResponse } from './../../models/authorization/UserAuthorizationResponse';
import UserService from './../../services/users/UserService';
import { UserResponseModel } from './../../models/users/UserResponseModel';
import { SessionStorageService } from './../../services/SessionStorageService';
import { SessionStorageKeys } from "../../constants/SessionStorageKeys";
import { UnauthorizedError } from './../../errors/UnauthorizedError';
import { LocalStorageService } from './../../services/LocalStorageService';
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";

export class UserStore {
    /**
     * Properties
     */
    @observable
    public user: Nullable<UserResponseModel>;

    @observable
    public token = "";

    /**
     * Ctor
     */
    constructor() {
        makeObservable(this);
    }

    /**
     * Actions
     */
    @action
    public async AuthorizeUser(request: UserAuthorizationRequest): Promise<UserAuthorizationResponse> {
        const authorizationResponse = await UserService.Authorize(request);

        this.SetUserAndToken(authorizationResponse);
        return authorizationResponse;
    }

    @action
    public SetUserAndToken(authorizationResponse: UserAuthorizationResponse): void {
        this.token = authorizationResponse.securityToken;
        this.user = authorizationResponse.user;
        SessionStorageService.setItem(SessionStorageKeys.JwtToken, authorizationResponse.securityToken);
    }

    /**
     * Strategy pattern function that removes the saved user if the token is expried or unauthorized request is made
     */
    @action
    public async MakeAuthorizedHttpRequest<T>(httpRequest: () => T): Promise<T | undefined> {
        try {
            const result = await httpRequest();
            return result;
        }
        catch (error) {
            if (error instanceof UnauthorizedError) {
                this.user = undefined;
                this.token = "";
                LocalStorageService.removeItem(LocalStorageKeys.AuthorizedResponse);
                return;
            }
            throw error;
        }
    }

}