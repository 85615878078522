

/**
 * A class that triggers downloads in JavaScript browser
 * API example new FileDownloader().Download(datasourceFunc, fileName);
 */
export class FileDownloader {
    /**
     *
     */
    constructor() { }


    public DownloadFromBase64(base64string: string, fileName: string) {
        const byteCharacters = atob(base64string);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        this.DownloadFromBytes(byteArray, fileName);
    }

    public DownloadFromBytes(byteArray: Uint8Array | Uint16Array | ArrayBuffer, fileName: string) {
        const blob = new Blob([new Uint8Array(byteArray)]);
        this.DownloadFromBlob(blob, fileName);
    }

    public DownloadFromBlob(blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a") as any;
        link.href = url;
        link.setAttribute("download", fileName); 
        document.body.appendChild(link);
        link.click();
    }
}