import { Box, TextField } from "@material-ui/core";
import React, { useState } from "react";
/**
 * Styles
 */
import "./Input.scss";
import "../input-styles.scss";

interface Props {
    placeholder?: string;
    Icon?: React.ReactElement;
    value?: string;
    valueSetter?: (value: string) => void;
    onChange?: (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => void;
    onEnterPress?: () => void;
    disabled?: boolean;
    type?: "password" | "text" | "number";
}

const Input = (props: Props) => {
    const {
        value,
        onChange,
        valueSetter,
        onEnterPress,
        placeholder,
        Icon,
        disabled,
        type = "text",
    } = props;
    const [focused, setFocused] = useState<boolean>(false);

    const className = `pandologic-input ${Icon ? "with-icon" : ""} ${
        value || focused ? "filled" : ""
    }`;

    return (
        <Box className={`${className} ${disabled ? "disabled" : ""}`}>
            <TextField
                fullWidth
                value={value || ""}
                disabled={disabled || false}
                type={type}
                onChange={(e) => {
                    if (valueSetter) {
                        let newValue = e.target.value;
                        valueSetter(newValue);
                    }
                    if (onChange) {
                        onChange(e);
                    }
                }}
                onKeyDown={(e) => {
                    if (onEnterPress && e.key.toLowerCase() === "enter") {
                        onEnterPress();
                    }
                }}
                onFocus={(e) => {
                    setFocused(true);
                }}
                onBlur={(e) => {
                    setFocused(false);
                }}
                variant="outlined"
                label={placeholder}
            />
            <Box className="icon">{Icon}</Box>
        </Box>
    );
};

export default Input;
