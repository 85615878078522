export enum EGrantType {
    SalesMan = 1,
    Admin = 2
}


export class User {
    /**
     *
     */
    constructor(
        public userId: number,
        public name: string,
        public email: string,
        public password: string,
        public GrantType: EGrantType,
    ) {

    }
}