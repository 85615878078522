import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    whiteSection: {
        boxShadow: "0 1px 8px 0 rgba(0,0,0,0.1) !important",
        borderRadius: "5px",
        backgroundColor: "#FFFFFF",
    },
    actionButton: {
        width: "128px",
        height: "40px",
        textTransform: "none",
    },
    accountTitle: {
        fontWeight: 300,
        width: "60%",
        maxWidth: "500px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));
