import { AxiosResponse } from "axios";
import { inherits } from "util";

export class FetchError<T> extends Error {
  /**
   *
   */
  constructor(axiosResponse: AxiosResponse<T>) {
    super(`${axiosResponse.data}`);
    
  }
}