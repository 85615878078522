export type severity = "error" | "warning" | "info";

export class ValidatorResponse {
    /**
     *
     */
    constructor(
        public isValid: boolean,
        public severity: severity,
        public errorMessage: string
    ) {

    }
}

export interface IInputValidator<T> {
    isValid(requestParams: T): ValidatorResponse;
    notifyError(validatorResponse: ValidatorResponse): void;
}

