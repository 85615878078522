import { makeStyles } from "@material-ui/core";
import pprBg from "../../assets/images/ppr-background.jpg";

export const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url(${pprBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "calc(100vh - 80px)",
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)"
    },
    paperWrapper: {
        position: "relative",
        height: "438px",
        width: "641px",
        maxWidth: "70%",
        maxHeight: "70%",
        paddingLeft: theme.spacing(14),
        paddingTop: theme.spacing(14),
    },
    paper: {
        padding: theme.spacing(12),
        width: "100%",
        minHeight: "100%",
    },
    pprTitle: {
        color: "#485465",
        fontFamily: "Open Sans",
        fontSize: "20px",
        fontWeight: 800,
        letterSpacing: 0,
        lineHeight: "27px",
    },
    subTitle: {
        color: "#485465",
        fontFamily: "Open Sans",
        fontSize: "16px",
        lineHeight: "22px",
        marginBottom: theme.spacing(1),
    },
    or: {
        color: "#999B9D",
        fontFamily: "Open Sans",
        fontSize: "16px",
        lineHeight: "22px",
    },
    clearButton: {
        color: "#3992B3",
        fontFamily: "Open Sans",
        fontSize: "14px",
        lineHeight: "19px",
        textTransform: 'none'
    },
}));
