import React, { useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import bgImg from "../../assets/images/login.jpg";
import Input from "../common/inputs/input/Input";
import { message } from "antd";
import { UserAuthorizationRequest } from "../../models/authorization/UserAuthorizationRequest";
import { RootStoreContext } from "./../../stores/RootStoreContext";
import { StringHelper } from "./../../services/StringHelper";
import Loader from "../common/feedback/loader/Loader";
import { RouterProps, withRouter } from "react-router";
import { Routes } from "../../constants/Routes";
import { LocalStorageService } from "./../../services/LocalStorageService";
import { LocalStorageKeys } from "../../constants/LocalStorageKeys";
import useLoadUserIfSaved from "./../../hooks/useLoadUserIfSaved";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import shadows from "@material-ui/core/styles/shadows";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: theme.palette.background.paper,
        padding: theme.spacing(10),
        borderRadius: "5px",
        width: 400,
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        // margin: theme.spacing(3, 0, 2),
    },    
    bgGradient: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        backgroundImage:
            "linear-gradient(30deg, rgba(138, 212, 61, .5) 0%, rgba(9, 170, 196, .5) 100%)",
        zIndex: -1,
    },
}));

type Props = {} & RouterProps;

const LoginPage = (props: Props) => {
    const classes = useStyles();
    const { history } = props;
    const { userStore, uiStore } = useContext(RootStoreContext);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email || !password) {
            return message.error(
                "Pleaes make sure you enter email and password"
            );
        }

        if (!StringHelper.validateEmail(email)) {
            return message.error(
                "Please make sure the email you enter is a correct email"
            );
        }

        try {
            setLoader(true);
            const authorizationRequest = new UserAuthorizationRequest(
                email,
                password
            );
            const authorizationResponse = await userStore.AuthorizeUser(
                authorizationRequest
            );

            if (rememberMe) {
                LocalStorageService.setItem(
                    LocalStorageKeys.AuthorizedResponse,
                    authorizationResponse
                );
            }

            message.success(`Welcome in ${authorizationResponse.user.name}`);
            history.push(Routes.InputPage);
            return;
        } catch (error: any) {
            message.error(error.message);
        } finally {
            setLoader(false);
        }
    };

    useLoadUserIfSaved();

    if (uiStore.applicationLoader) {
        return (
            <Box display="flex" justifyContent="center" mt={10}>
                <Loader style={{ width: "200px", height: "200px" }} />
            </Box>
        );
    }

    if (userStore.user && userStore.token) {
        return <Redirect to={Routes.InputPage} />;
    }

    return (
        <Container component="main" maxWidth="xs">
           
            {/* <Box className={classes.bgGradient}></Box> */}
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}>
                    <Box mt={4}>
                        <Input
                            placeholder="Email"
                            value={email}
                            valueSetter={setEmail}
                        />
                    </Box>
                    <Box mt={4}>
                        <Input
                            type="password"
                            value={password}
                            valueSetter={setPassword}
                            placeholder="Password"
                        />
                    </Box>
                    <Box mt={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        setRememberMe(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            }
                            label="Remember me"
                        />
                    </Box>
                    <Box mt={4}>
                        {loader ? (
                            <Box display="flex" justifyContent="center">
                                <Loader style={{ width: 75, height: 75 }} />
                            </Box>
                        ) : (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Sign In
                            </Button>
                        )}
                    </Box>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
        </Container>
    );
};

export default withRouter(observer(LoginPage));
