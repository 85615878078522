export class UserAuthorizationRequest {
    /**
     *
     */
    constructor(
        public email: string,
        public password: string,
    ) {

    }
}