import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    blackIcon: {
        color: "#485465",
    },
    dateSelect: {
        width: 55,
        fontSize: "25px",
        fontWeight: 300,
        color: "#485465",
        fontFamily: "Comfortaa",
    },
    resetButton: {
        "&.disabled": {
            borderColor: "#C2C3C4 !important",
            color: "#C2C3C4 !important",
            pointerEvents: "none !important",
        },
    },
}));
