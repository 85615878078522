import { AxiosResponse } from "axios";
import { inherits } from "util";

export class UnauthorizedError extends Error {
  /**
   *
   */
  constructor() {
    super(`Unauthorized http request`);
    
  }
}