import { ServiceUrls } from "../../constants/ServiceUrls";
import { BaseService } from "../BaseService";
import { UserAuthorizationRequest } from './../../models/authorization/UserAuthorizationRequest';
import { UserAuthorizationResponse } from './../../models/authorization/UserAuthorizationResponse';

export class UserService extends BaseService {

    async Authorize(request: UserAuthorizationRequest): Promise<UserAuthorizationResponse> {
        const result = await this.post<UserAuthorizationResponse>(`/authorize`, request);
        return result;
    }

}

export default new UserService(ServiceUrls.User);