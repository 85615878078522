import { ValueFormatterParams } from 'ag-grid-community';
import { PredictionGridModel } from '../../../../stores/prediction/datamodels/PredictionGridModel';

export const gridColumns = [
    {
        groupName: "Job Details",
        groupData: [
            {
                field: "jobId",
                headerName: "Job Id",
                properties: {
                    
                },
            },
            {
                field: "jobTitle",
                headerName: "Job Title",
                properties: {
                    cellClass: "ag-link-column",
                    onCellClicked: (event: any) => {
                        const data: PredictionGridModel = event.data;
                        window.open(data.externalApplyUrl, "_blank");
                    },
                },
            },
            {
                field: "location",
                headerName: "Location",
                properties: {
                   
                },

            },
            {
                field: "category",
                headerName: "Category",
                properties: {
                    headerClass: "ag-right-border-column",
                    cellClass: "ag-right-border-column",
                    
                },
            },
        ]
    },
    {
        groupName: "Predicted Performance",
        groupData: [
            {
                field: "clicks",
                headerName: "Clicks",
                properties: {
                    type: "rightAligned",
                },
            },
            {
                field: "applicants",
                headerName: "Applicants",
                properties: {
                    type: "rightAligned",
                    // filter: "agMultiColumnFilter",
                },
            },
            {
                field: "conversionRate",
                headerName: "Conv Rate",
                properties: {
                    valueFormatter: (params: ValueFormatterParams) => `${params.value}%`,
                    type: "rightAligned",
                    // filter: "agMultiColumnFilter",
                    // filterParams: {
                    //     filters: [
                    //         {
                    //             filter: 'agNumberColumnFilter',

                    //         },
                    //         {
                    //             filter: 'agSetColumnFilter',
                    //             display: 'subMenu',
                    //         }
                    //     ]
                    // }
                },

            },
            {
                field: "budget",
                headerName: "Est. Cost",
                properties: {
                    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
                    type: "rightAligned",
                    // filter: "agMultiColumnFilter",
                    // filterParams: {
                    //     filters: [
                    //         {
                    //             filter: 'agNumberColumnFilter',

                    //         },
                    //         {
                    //             filter: 'agSetColumnFilter',
                    //             display: 'subMenu',
                    //         }
                    //     ]
                    // }
                },
            },
            {
                field: "cpc",
                headerName: "Avg CPC",
                properties: {
                    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
                    type: "rightAligned",
                    // filter: "agMultiColumnFilter",
                    // filterParams: {
                    //     filters: [
                    //         {
                    //             filter: 'agNumberColumnFilter',

                    //         },
                    //         {
                    //             filter: 'agSetColumnFilter',
                    //             display: 'subMenu',
                    //         }
                    //     ]
                    // }
                },
            },
            {
                field: "cpa",
                headerName: "Avg CPA",
                properties: {
                    valueFormatter: (params: ValueFormatterParams) => `$${params.value}`,
                    type: "rightAligned",
                    // filter: "agMultiColumnFilter",
                    // filterParams: {
                    //     filters: [
                    //         {
                    //             filter: 'agNumberColumnFilter',

                    //         },
                    //         {
                    //             filter: 'agSetColumnFilter',
                    //             display: 'subMenu',
                    //         }
                    //     ]
                    // },
                },
            },
        ]
    },
    {
        groupName: "Market Indicators",
        groupData: [
            {
                field: "clickRange",
                headerName: "Click Range",
                properties: {
                    headerClass: "ag-left-border-column",
                    cellClass: "ag-left-border-column",
                    filter: "agSetColumnFilter",
                    // filterParams: {
                    //     excelMode: 'mac',
                    // },
                },
            },
            {
                field: "competition",
                headerName: "Competition",
                properties: {
                    filter: "agSetColumnFilter",
                    // filterParams: {
                    //     excelMode: 'mac',
                    // },
                },
            },
            {
                field: "demandFactor",
                headerName: "Demand",
                properties: {
                    filter: "agSetColumnFilter",
                    // filterParams: {
                    //     excelMode: 'mac',
                    // },
                },
            },
            {
                field: "supplyFactor",
                headerName: "Supply",
                properties: {
                    headerClass: "ag-right-border-column",
                    cellClass: "ag-right-border-column",
                    filter: "agSetColumnFilter",
                    // filterParams: {
                    //     excelMode: 'mac',
                    // },
                },
            },
        ]
    },
]