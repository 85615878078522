import { message } from 'antd';
import { IInputValidator, severity, ValidatorResponse } from './IInputValidator';


export abstract class BaseInputValidator<T> implements IInputValidator<T> {
    abstract isValid(requestParams: T): ValidatorResponse;

    notifyError(validatorResponse: ValidatorResponse): void {
        // tightly coupled to ant design
        message[validatorResponse.severity](validatorResponse.errorMessage);
    }
}
