import { Account } from "./../../models/Account";
import { Box, Button } from "@material-ui/core";
import AutoCompleteInput from "../common/inputs/auto-complete-input/AutoCompleteInput";
import Input from "../common/inputs/input/Input";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Loader from "../common/feedback/loader/Loader";

export class InputPageBuilder {
    /**
     *
     */
    constructor(public classes: any) {}

    public Title(title: string) {
        return <h1 className={this.classes.pprTitle}>{title}</h1>;
    }

    public AccountNameAutocompleteInput(
        readyAccounts: Account[],
        selectedAccount: Account | null,
        setSelectedAccount: (account: Account | null) => void,
        handleSubmit: () => void,
        loading: boolean
    ) {
        return (
            <Box my={2}>
                <h3 className={this.classes.subTitle}>Select a client</h3>
                {loading ? (
                    <Box display="flex" justifyContent="center">
                        <Loader style={{ width: 70, height: 70 }} />
                    </Box>
                ) : (
                    <AutoCompleteInput
                        placeholder="Account Name"
                        data={readyAccounts}
                        getOptionCb={(v) => v?.accountName ?? ""}
                        onChange={(v) => setSelectedAccount(v)}
                        onEnterPress={handleSubmit}
                        value={selectedAccount}
                    />
                )}
            </Box>
        );
    }

    public GoalSettingSection(
        budget: string,
        setBudget: (value: string) => void,
        applicants: string,
        setApplicants: (value: string) => void,
        handleSubmit: () => void
    ) {
        return (
            <Box mt={4} data-about="set-your-goals">
                <h3 className={this.classes.subTitle}>Set your goal</h3>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between">
                    <Box width="45%">
                        <Input
                            placeholder="Advertising Budget"
                            Icon={<MonetizationOnOutlinedIcon />}
                            value={budget}
                            valueSetter={setBudget}
                            disabled={!!applicants}
                            onEnterPress={handleSubmit}
                        />
                    </Box>
                    <p className={this.classes.or}>OR</p>
                    <Box width="45%">
                        <Input
                            placeholder="Applicants"
                            Icon={<PeopleAltIcon />}
                            value={applicants}
                            valueSetter={setApplicants}
                            disabled={!!budget}
                            onEnterPress={handleSubmit}
                        />
                    </Box>
                </Box>
            </Box>
        );
    }

    public ButtonsSection(
        loading: boolean,
        handleSubmit: () => void,
        handleClear: () => void
    ) {
        if (loading) {
            return (
                <Box
                    style={{ transform: "translateY(35px)" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Loader style={{ width: 75, height: 75 }} />
                </Box>
            );
        }

        return (
            <>
                <Box
                    data-about="generate-prediction-button"
                    width="100%"
                    mt={8}>
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}>
                        Generate Prediction Report
                    </Button>
                </Box>

                <Box data-about="clear-button" textAlign="center" mt={4}>
                    <Button
                        className={this.classes.clearButton}
                        onClick={handleClear}
                        color="secondary">
                        Clear form
                    </Button>
                </Box>
            </>
        );
    }
}
