import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    blackIcon: {
        color: "#485465",
    },
    dateSelect: {
        width: 55,
        fontSize: "25px",
        //paddingLeft: "5px",
        fontWeight: 300,
        color: "#485465",
        fontFamily: "Comfortaa",
    },
    dateDiv: {
        [theme.breakpoints.down(1400)]: {
            fontSize: '20px'
        },
    },
}));
