
export class NumbersHelper {

    public static numberWithCommas(num: number | string): string {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    public static stripCommas(formattedNumber: string): number {
        return Number(formattedNumber.replace(",", ""));
    }

    public static isNumber(value: string): boolean {
        if (value === "") return false;
        value = value.replaceAll(",", "");

        try {
            return !isNaN(Number(value));
        }
        catch {
            return false;
        }
    }

    public static Round(value: number, decimalPoints: number): number {
        if (decimalPoints === 0) {
            return Math.round(value);
        }

        const multiplier = Math.pow(10, decimalPoints);
        return Math.round(value * multiplier) / multiplier;
    }

    public static toFixed(value: number, decimalPoints: number): number {
        return Number(value.toFixed(decimalPoints));
    }
}