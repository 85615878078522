import React, { useState, useContext, useEffect, useRef } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { RootStoreContext } from "../../../../stores/RootStoreContext";
import { observer } from "mobx-react-lite";
import { gridColumns } from "./gridColumns";
import "ag-grid-community";
import "ag-grid-enterprise";
import "./ag-grid-custom.scss";

const JobsGrid = () => {
    const { predictionStore } = useContext(RootStoreContext);
    // const gridData = useRef(predictionStore.GetGridData());
    const gridRef = useRef(null);
    const competitionScoreChartRef = useRef(null);
    const clickRangeChartRef = useRef(null);
    const [gridData, setGridData] = useState([]);

    //#region Charts Logic
    const createCompetitionScoreChart = (params, withDestroy) => {
        if (withDestroy) {
            competitionScoreChartRef.current.destroyChart();
        }

        const competitionScoreChart = {
            cellRange: {
                columns: ["jobId", "competition"],
            },
            chartType: "groupedBar",
            chartContainer: document.querySelector("#competitionScoreChart"),
            aggFunc: "count",
            chartThemeOverrides: {
                common: {
                    title: {
                        text: "Jobs by Competition Score",
                    },
                    tooltipClass: "my-tooltip-class",
                },
            },
        };
        competitionScoreChartRef.current = params.api.createRangeChart(
            competitionScoreChart
        );
    };

    const createClickRangeChart = (params, withDestroy) => {
        if (withDestroy) {
            clickRangeChartRef.current.destroyChart();
        }
        const clickRangeChart = {
            cellRange: {
                columns: ["jobId", "clickRange"],
            },
            chartType: "groupedBar",
            chartContainer: document.querySelector("#clickRangeChart"),
            aggFunc: "count",
            chartThemeOverrides: {
                common: {
                    title: {
                        text: "Jobs by Click Range Score",
                    },
                    tooltipClass: "my-tooltip-class",
                },
            },
        };
        clickRangeChartRef.current =
            params.api.createRangeChart(clickRangeChart);
    };

    const onFirstDataRendered = (params) => {
        createCompetitionScoreChart(params, false);
        createClickRangeChart(params, false);

        params.api.deselectAll();
        params.api.clearRangeSelection();
    };

    const customChartThemes = {
        myCustomTheme: {
            baseTheme: "ag-pastel",
            palette: {
                fills: ["#30789F", "#a2bf8a", "#ebcc87"],
                strokes: ["#225A78", "#718661", "#a48f5f"],
            },
            overrides: {
                common: {
                    padding: {
                        top: 20,
                    },
                    title: {
                        enabled: true,
                        fontSize: 28,
                        fontFamily: "Open Sans, sans-serif",
                        paddingY: 40,
                        color: "#225A78",
                        texAlign: "left",
                    },
                    legend: {
                        enabled: false,
                        item: {
                            label: {
                                fontStyle: "italic",
                                fontWeight: "bold",
                                fontSize: 25,
                                fontFamily: "Palatino, serif",
                                color: "#485465",
                            },
                        },
                    },
                },
            },
        },
    };
    //#endregion

    const sideBarConfiguration = {
        toolPanels: [
            {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
            },
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
            },
        ],
        // defaultToolPanel: "filters",
        position: "right",
    };

    useEffect(() => {
        setGridData(predictionStore.GetGridData());
    }, [
        predictionStore.activeCampaignStrategy,
        predictionStore.predictionSettings,
    ]);

    return (
        <main>
            <div
                className="ag-theme-alpine"
                style={{
                    maxHeight: 815,
                    height: 250 + gridData.length * 38,
                    width: "100%",
                }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={gridData || []}
                    defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true,
                        suppressMovable: true,
                    }}
                    animateRows={true}
                    pagination={true}
                    paginationPageSize={15}
                    enableRangeSelection={true}
                    enableCharts={true}
                    context={false}
                    sideBar={sideBarConfiguration}
                    chartThemes={[
                        "myCustomTheme",
                        "ag-pastel",
                        "ag-material-dark",
                        "ag-vivid-dark",
                        "ag-solar",
                    ]}
                    customChartThemes={customChartThemes}
                    onGridReady={(e) => {
                        e.api.sizeColumnsToFit();
                    }}
                    onFilterChanged={(e) => {
                        if (predictionStore.gridFilterOptions.useOldFilter) {
                            // console.log(
                            //     predictionStore.gridFilterOptions
                            //         .agGridFilterModel
                            // );

                            const filteredColumns = Object.keys(
                                predictionStore.gridFilterOptions
                                    .agGridFilterModel
                            );
                            // console.log(filteredColumns);
                            filteredColumns.forEach((column) => {
                                const columnInstance =
                                    e.api.getFilterInstance(column);
                                columnInstance.setModel(
                                    predictionStore.gridFilterOptions
                                        .agGridFilterModel[column]
                                );
                                columnInstance.applyModel();
                            });
                            e.api.onFilterChanged();

                            predictionStore.gridFilterOptions.setUseOldFilterAs(
                                false
                            );
                            return;
                        }

                        const filterModel = e.api.getFilterModel();
                        predictionStore.gridFilterOptions.setUseOldFilterAs(
                            false
                        );

                        const includedPredictionsAfterFilter = {};
                        e.api.forEachNodeAfterFilter((rowNode) => {
                            includedPredictionsAfterFilter[
                                rowNode.data.jobId.toString()
                            ] = true;
                        });

                        if (
                            Object.keys(includedPredictionsAfterFilter)
                                .length == 0
                        ) {
                            includedPredictionsAfterFilter["empty"] = true;
                        }

                        // console.log(
                        //     includedPredictionsAfterFilter,
                        //     Object.keys(includedPredictionsAfterFilter).length
                        // );

                        predictionStore.SetFilteredPredictions(
                            includedPredictionsAfterFilter
                        );
                        predictionStore.setGridFilterOptions(
                            includedPredictionsAfterFilter,
                            filterModel
                        );

                        createClickRangeChart(e, true);
                        createCompetitionScoreChart(e, true);
                        // e.api.deselectAll();
                        // e.api.clearRangeSelection();
                    }}
                    chartThemeOverrides={{
                        bar: {
                            series: {
                                fillOpacity: 1,
                                strokeOpacity: 1,
                                strokeWidth: 2,
                                shadow: {
                                    enabled: true,
                                    color: "rgba(0, 0, 0, 0.3)",
                                    xOffset: 10,
                                    yOffset: 5,
                                    blur: 8,
                                },
                                highlightStyle: {
                                    fill: "#225A78",
                                    stroke: "#225A78",
                                },
                            },
                        },
                        histogram: {
                            series: {
                                fillOpacity: 1,
                                strokeOpacity: 1,
                                strokeWidth: 2,
                                shadow: {
                                    enabled: true,
                                    color: "rgba(0, 0, 0, 0.3)",
                                    xOffset: 10,
                                    yOffset: 5,
                                    blur: 8,
                                },
                                highlightStyle: {
                                    fill: "#30789F",
                                    stroke: "#30789F",
                                },
                            },
                        },
                    }}
                    autoSizePadding
                    onFirstDataRendered={onFirstDataRendered}>
                    {createGrid(gridColumns)}
                </AgGridReact>
            </div>
        </main>
    );
};

//#region Helper Components

const createGrid = (gridColumns) => {
    return gridColumns.map((group) =>
        createGridGroup(group.groupName, group.groupData)
    );
};

const createGridGroup = (title, groupColumns) => {
    return (
        <AgGridColumn headerName={title}>
            {groupColumns.map((columnData) => (
                <AgGridColumn
                    {...columnData.properties}
                    field={columnData.field}
                    headerName={columnData.headerName}></AgGridColumn>
            ))}
        </AgGridColumn>
    );
};

//#endregion

export default observer(JobsGrid);
