export class GridFilterOptions {
    /**
     *
     */
    public filteredPredictionsIdMap: { [key: string]: boolean } = {};
    public agGridFilterModel: { [key: string]: any } = {};
    public useOldFilter: boolean;

    constructor(
        filteredPredictionsIdMap: { [key: string]: boolean },
        agGridFilterModel: { [key: string]: any }
    ) {
        this.filteredPredictionsIdMap = filteredPredictionsIdMap;
        this.agGridFilterModel = agGridFilterModel
        this.useOldFilter = false;
    }


    public setUseOldFilterAs(state: boolean): void {
        this.useOldFilter = state;
    }


}