import React from "react";
import { MapPoint } from "../../../../stores/prediction/datamodels/MapPoint";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WorkIcon from "@material-ui/icons/Work";
import { colorsDictionary } from "./JobsMap";
import ReactMapGL, { Marker } from "react-map-gl";

export const CreateIconsOnMap = (
    getJobsDisplayedOnMap: MapPoint[],
    onIconClick: (job: MapPoint) => void
): any => {
    return getJobsDisplayedOnMap.map((job, index: number) => (
        <Marker key={index} latitude={job.latitude} longitude={job.longitude}>
            <FiberManualRecordIcon
                onClick={(e) => {
                    e.preventDefault();
                    onIconClick(job);
                }}
                style={{
                    cursor: "pointer",
                    color: colorsDictionary[job.competitionCategory],
                    // color: "#225A78",
                    width: Math.min(50, 20 + job.totaljobs * 0.125),
                    height: Math.min(50, 20 + job.totaljobs * 0.125),
                }}
            />
        </Marker>
    ));
};
