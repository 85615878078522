export class PreloadRequest {
    /**
     *
     */
    constructor(
        public accountId: number,
        public margin: number,
        public cvh: number,
        public userId: number,
        public areExpansionsExcluded: boolean
    ) {}
}
