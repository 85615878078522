import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import VerticalDivider from "../vertical-divider/VerticalDivider";
import "./Footer.scss";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { Page } from "../../../stores/UIStore";
import { observer } from 'mobx-react-lite';

interface Props {}

const Footer = (props: Props) => {
    const { uiStore } = useContext(RootStoreContext);
    const buildFooterStyles = (): React.CSSProperties => {
        if (uiStore.currentPage === Page.PredictionPage) {
            return {
                position: "relative",
            };
        }

        return {
            position: "absolute",
            bottom: 0,
            opacity: 0.6,
        };
    };
    return (
        <footer className="text text-gray" style={buildFooterStyles()}>
            <Box
                py={2}
                px={4}
                data-about="wrapper"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box component="p">
                    © {new Date().getFullYear()} Veritone Inc. All rights reserved
                </Box>

                <Box display="flex" className="terms-and-conditions">
                    <a
                        href="https://www.veritone.com/privacy/"
                        target="_blank">
                        Privacy Policy
                    </a>
                    <Box mx={1}>
                        <VerticalDivider height="20px" />
                    </Box>
                    <a
                        href="https://www.veritone.com/cookie-policy/"
                        target="_blank">
                        Cookie Policy
                    </a>
                    <Box mx={1}>
                        <VerticalDivider height="20px" />
                    </Box>
                    <a
                        href="https://www.veritone.com/terms/"
                        target="_blank">
                        Terms of Use
                    </a>
                </Box>
            </Box>
        </footer>
    );
};

export default observer(Footer);
