import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    button: {
        width: 85,
        fontWeight: 600,
        textTransform: 'none'
    },
    buttonActive: {
        width: 85,
        backgroundColor: '#EEEEEE',
        fontWeight: 600,
        textTransform: 'none'
    }
}));
