/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box } from "@material-ui/core";
/**
 * Styles
 */
import "../input-styles.scss";
import "./AutoCompleteInput.scss";
import { observer } from "mobx-react-lite";
import { FilterOptionsState } from "@material-ui/lab";

interface Props<T> {
    placeholder?: string;
    data: T[];
    getOptionCb: (value: T) => string;
    onChange?: (value: T | null) => void;
    onEnterPress?: () => void;
    filterOptions?: ((options: T[], state: FilterOptionsState<T>) => T[]) | undefined
    value?: T;
}

function AutoCompleteInput<T>(props: Props<T>) {
    const {
        placeholder,
        data,
        getOptionCb,
        onChange,
        onEnterPress,
        value,
        filterOptions
    } = props;

    return (
        <Box className="pandologic-input pandologic-autocomplete">
            <Autocomplete
                id="combo-box-demo"
                options={data}
                value={value}
                filterOptions={filterOptions || undefined}
                onKeyDown={(e) => {
                    if (onEnterPress && e.key.toLowerCase() === "enter") {
                        onEnterPress();
                    }
                }}
                onChange={(_, value) => {
                    if (onChange) {
                        onChange(value);
                    }
                }}
                getOptionLabel={(option) => getOptionCb(option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder}
                        variant="outlined"
                    />
                )}
            />
        </Box>
    );
}

export default observer(AutoCompleteInput);
