import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import VerticalDivider from "../vertical-divider/VerticalDivider";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { Routes } from "../../../constants/Routes";
import { observer } from "mobx-react-lite";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { SessionStorageService } from "../../../services/SessionStorageService";
import { SessionStorageKeys } from "../../../constants/SessionStorageKeys";
import { LocalStorageService } from "../../../services/LocalStorageService";
import { LocalStorageKeys } from "../../../constants/LocalStorageKeys";

import pandologicLogo from "../../../assets/images/plogic_Logo_W.png";
import veritoneLogo from "../../../assets/images/Veritone-logos.svg";
import pandoSvg from "../../../assets/images/PIQ_icon.svg";
import veritoneSvg from "../../../assets/images/Veritone_icon.svg";

import "./Header.scss";

interface Props extends RouteComponentProps {}

const Header = (props: Props) => {
    const { history } = props;
    const { userStore } = useContext(RootStoreContext);

    const redirectToAdminPage = () => {
        if (userStore.user) {
            history.push(Routes.AdminPreload);
        }
    };

    const redirectToInputPage = () => {
        if (userStore.user) {
            history.push(Routes.InputPage);
        }
    };

    const cursorPointerIfLoggedIn = () => {
        const styles: React.CSSProperties = {};
        if (userStore.user) {
            styles["cursor"] = "pointer";
        }
        return styles;
    };

    const logOut = () => {
        SessionStorageService.removeItem(SessionStorageKeys.JwtToken);
        LocalStorageService.removeItem(LocalStorageKeys.AuthorizedResponse);
        history.push(Routes.Login);
    };

    return (
        <header id="header">
            <Box
                data-about="wrapper"
                py={1}
                px={3}
                height="100%"
                display="flex"
                alignItems="center">
                <Box
                    data-about="Logo"
                    className="pandologic-logo"
                    display="flex"
                    style={{ objectFit: 'initial' }}>
                    <img src={veritoneLogo} alt="Veritone logo" />
                </Box>
                {/* <Box
                    className="header-title"
                    ml={2}
                    display="flex"
                    alignItems="center">
                    <Box component="h2" className="text-white">
                        Hire Intelligence
                    </Box>
                </Box> */}
                <Box
                    data-about="Tools"
                    display="flex"
                    alignItems="center"
                    marginLeft="auto">
                    <Box display="flex" alignItems="center">
                        {HeaderIcon(
                            "Home",
                            cursorPointerIfLoggedIn(),
                            redirectToInputPage,
                            <HomeIcon {...getIconProps()} />
                        )}
                        {HeaderIcon(
                            "Admin",
                            cursorPointerIfLoggedIn(),
                            redirectToAdminPage,
                            <SettingsIcon {...getIconProps()} />
                        )}
                        {HeaderIcon(
                            "VHP",
                            cursorPointerIfLoggedIn(),
                            () => {
                                window.open(
                                    "https://www.pandoiq.com/login",
                                    "_blank"
                                );
                            },
                            <img
                                src={veritoneSvg}
                                alt="Veritone icon"
                                {...getIconProps()}
                            />
                        )}
                        {HeaderIcon(
                            "Logout",
                            cursorPointerIfLoggedIn(),
                            logOut,
                            <ExitToAppIcon {...getIconProps()} />
                        )}
                    </Box>
                </Box>
            </Box>
        </header>
    );
};

function HeaderIcon(
    text: string,
    style: React.CSSProperties,
    onClick: () => void,
    icon: React.ReactElement
) {
    const Icon = icon as any;
    return (
        <Box display="flex" justifyContent="space-between" className="header-icon">
            <Box>
                <VerticalDivider height="80px" />
            </Box>
            <Box
                style={{ ...style }}
                onClick={onClick}
                component="h4"
                className="text text-white"
                display="flex"
                alignItems="center"
                mx={6}>
                {icon}
                {text}
            </Box>
        </Box>
    );
}

function getIconProps(): any {
    return {
        fontSize: "small",
        style: { transform: "translateX(-10px)" },
    };
}

export default withRouter(observer(Header));
