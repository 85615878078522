import React from "react";
import { Box, Button } from "@material-ui/core";
import { MapPoint } from "../../../../../stores/prediction/datamodels/MapPoint";
import { colorsDictionary } from "../JobsMap";

interface Props {}
export const PopupContentRow = (title: string, content: string | number) => {
    return (
        <Box className="popup-section body" p={2}>
            <Box>
                <h6 className="text body-title">{title}</h6>
                <p className="text body-content">{content}</p>
            </Box>
        </Box>
    );
};

export const PopupContentTitle = (title: string) => {
    return (
        <Box className="popup-section body" p={2}>
            <h1
                className="text body-title"
                style={{
                    fontSize: "14px",
                    marginBottom: "-14px",
                    fontWeight: "bold",
                }}>
                Jobs by Competition:
            </h1>
        </Box>
    );
};

export const CreateContentRows = (point: MapPoint) => {
    return (
        <>
            {PopupContentRow("Jobs", point.totaljobs)}

            {PopupContentTitle("Jobs by Competition:")}
            {PopupContentRow("Low", point.totalLowCompetition)}
            {PopupContentRow("Medium", point.totalMediumCompetition)}
            {PopupContentRow("High", point.totalHighCompetition)}
        </>
    );
};

export const PopupTitle = (title: string, category: string) => {
    return (
        <Box
            className="popup-section top"
            p={2}
            style={{
                background: colorsDictionary[category],
            }}>
            <Box
                className="text"
                fontWeight="normal"
                fontSize={18}
                color="#fff">
                {title}
            </Box>
        </Box>
    );
};
