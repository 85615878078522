import React, { useContext, useState } from "react";
import { Box } from "@material-ui/core";
import "./LayoutTopSectionColumn.scss";

interface Props {
    children: React.ReactNode;
    title?: string;
    icon?: React.ReactNode;
    padLeft?: boolean;
}

const LayoutTopSectionColumn = (props: Props) => {
    const { title, icon, children, padLeft = false } = props;
    return (
        <Box
            className="top-section-column"
            display="flex"
            flexDirection="column"
            height={65}
            ml={padLeft ? 5 : 0}
            justifyContent="space-between">
            <Box display="flex" alignItems="center" minHeight={20}>
                {icon}
                <Box component="p" className="text text-gray" ml={1}>
                    {title}
                </Box>
            </Box>

            <Box mt={2}>{children}</Box>
        </Box>
    );
};

export default LayoutTopSectionColumn;
