import { Box, Slide, withStyles } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes } from "./constants/Routes";
import Header from "./components/common/header/Header";
import PredictionPage from "./components/predictions-page/PredictionPage";
import { observer } from "mobx-react-lite";
import Footer from "./components/common/footer/Footer";
import InputPage from "./components/input-page/InputPage";
import { getEnvironment } from "./config/EnvironmentUrls";
import LoginPage from "./components/login-page/LoginPage";
import AuthorizedRoute from "./hoc/AuthorizedRoute";
import useKeyboardListener from "./hooks/useKeyboardListener";
import { Guid } from "./services/Guid";
import { RouteSetup } from "./config/RouteSetup";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import { makeStyles } from '@material-ui/core/styles';

const SnackBarProviderCustomTheme = withStyles({
    variantInfo: {
        backgroundColor: 'yellow' 
    }
})(SnackbarProvider);

const styles = {
    // success: { backgroundColor: 'purple' },
    // error: { backgroundColor: 'blue' },
    // warning: { backgroundColor: 'green' },
    info: { backgroundColor: '#242C37 !important' }
};

function App() {
    useKeyboardListener(Guid.NewGuid(), "nadar".split(""), () => {
        window.alert("Give kudos to Nadar for this amazing project ;)");
    });

    const classes = makeStyles(styles)();

    const ApplicationRoutes = () => {
        return (
            <Switch>
                {RouteSetup.GetRouteConfiguration().map(
                    (routeConfiguration) => {
                        if (routeConfiguration.isAuthorizedRoute) {
                            return (
                                <AuthorizedRoute
                                    exact
                                    key={routeConfiguration.path}
                                    path={routeConfiguration.path}>
                                    <routeConfiguration.reactComponent />
                                </AuthorizedRoute>
                            );
                        }
                        return (
                            <Route
                                exact
                                path={routeConfiguration.path}
                                key={routeConfiguration.path}>
                                <routeConfiguration.reactComponent />
                            </Route>
                        );
                    }
                )}
            </Switch>
        );
    };

 

    return (
        <div className="App">
            <SnackbarProvider
                maxSnack={3}
                classes={{
                    variantInfo: classes.info,
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                TransitionComponent={Slide as any}>
                <Router basename={getEnvironment().basePath}>
                    <Header />
                    <ApplicationRoutes />
                    <Footer />
                </Router>
            </SnackbarProvider>
        </div>
    );
}

export default observer(App);
