import { createMuiTheme } from "@material-ui/core";

export const MuiLightTheme = createMuiTheme({
    palette: {
        background: {
            default: "#FFF"
        },

        primary: {
            main: "#225a78"
        },
        secondary: {
            main: "#77992e"
        }
    },
    typography: {
        fontFamily: "Open Sans"
    },
    spacing: 5
});