export enum EPredictionType {
    NoRequest = "NoRequest",
    Budget = "Budget",
    Applicants = "Applicants"
}

export class UserPredictionRequest {
    /**
     *
     */
    constructor(
        public accountId: number,
        public predictionType: EPredictionType,
        public amount: number,
        public paginationIndex: number | null,
    ) { }
}