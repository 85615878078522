import { PredictionStore } from './prediction/PredictionStore';
import { UIStore } from "./UIStore";
import { UserStore } from './user/UserStore';

export class RootStore {
    /**
     * Props
     */

    public userStore: UserStore;
    public predictionStore: PredictionStore;
    public uiStore: UIStore;

    constructor() {
        this.userStore = new UserStore();
        this.predictionStore = new PredictionStore(this.userStore);
        this.uiStore = new UIStore();
    }
}