import mapboxgl from "mapbox-gl";
import React, { useState, useEffect, useContext, useMemo } from "react";
import ReactMapGL from "react-map-gl";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../../stores/RootStoreContext";
import { MapPoint } from "../../../../stores/prediction/datamodels/MapPoint";
import LocationPopup from "./components/LocationPopup";
import { CreateIconsOnMap } from "./JobsMap.factory";
import "./JobsMap.scss";

/*
resources: https://github.com/leighhalliday/mapbox-react-demo/blob/master/src/App.js
https://www.youtube.com/watch?v=JJatzkPcmoI
*/

export const colorsDictionary: any = {
    Low: "#008450",
    Medium: "#efb700",
    High: "#b81d13",
};

const ACCESS_TOKEN =
    "pk.eyJ1IjoibmFkYXJhIiwiYSI6ImNrbnJlZWwzcDI1ZnMydXBlbG95eDZ2MGYifQ.bHgDDmozMa_8MNeFMUf5iw";
const MAP_STYLE = "mapbox://styles/nadara/cknrf3x8w06ao17pqvbmmbn93";

interface Props {}
const JobsMap = (props: Props) => {
    const { predictionStore } = useContext(RootStoreContext);

    const [viewport, setViewport] = useState({
        latitude: predictionStore.predictionResult!.predictions[0].lat,
        longitude: predictionStore.predictionResult!.predictions[0].long,
        width: "100%",
        height: "100%",
        zoom: 3,
    });
    const [selectedJob, setSelectedJob] = useState<MapPoint | null>(null);

    const mapData: MapPoint[] = useMemo(() => {
        return predictionStore.GetJobsDisplayedOnMap;
    }, [predictionStore.filteredPredictions]);

    if (!predictionStore.GetJobsDisplayedOnMap) return <></>;

    return (
        <ReactMapGL
            {...viewport}
            mapboxApiAccessToken={ACCESS_TOKEN}
            mapStyle={MAP_STYLE}
            onViewportChange={(viewport: any) => {
                setViewport(viewport);
            }}
            onClick={(e) => {
                setSelectedJob(null);
            }}>
            {CreateIconsOnMap(mapData, (job) => {
                setSelectedJob(job);
            })}

            <LocationPopup
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
            />
        </ReactMapGL>
    );
};
export default React.memo(observer(JobsMap));

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
