import { ServiceUrls } from "../../constants/ServiceUrls";
import { BaseService } from "../BaseService";
import { Account } from '../../models/Account';
import { ApiRoutes } from './../../constants/ApiRoutes';

export class AccountService extends BaseService {
    public async GetAllAccounts(): Promise<Account[]> {
        const accounts = await this.get<Account[]>(ApiRoutes.Account.GetAllAccounts);
        return accounts;
    }
  
}

export default new AccountService(ServiceUrls.Account);