import { ServiceUrls } from "../../constants/ServiceUrls";
import { BaseService } from "../BaseService";
import { Account } from '../../models/Account';
import { UserPredictionRequest } from "../../models/prediction/UserPredictionRequest";
import { Prediction } from '../../models/prediction/Prediction';
import { PredictionResult } from "../../models/prediction/PredictionResult";
import { PreloadRequest } from './../../models/prediction/PreloadRequest';
import { CreatePredictionsExcelRequest } from './../../models/prediction/CreatePredictionsExcelRequest';
import { ApiRoutes } from "../../constants/ApiRoutes";
import { AuditPredictionRequest } from "../../models/predictionsAudit/AuditPredictionRequest";
import { CreatePredictionsExcelResponse } from './../../models/prediction/CreatePredictionsExcelResponse';
import { BaseResponse } from "../../models/BaseResponse";
import { UserFilterResult } from "../../models/prediction/UserFilterResult";
import { CreateExcelFromBatchRequest } from './../../models/prediction/CreateExcelFromBatchRequest';

export class PredictionService extends BaseService {

    async PreloadAccount(preloadRequest: PreloadRequest): Promise<string> {
        const result = this.post<string>(ApiRoutes.Predictions.PreloadAccount, preloadRequest);
        return result;
    }

    async GetPrediction(predictionRequest: UserPredictionRequest): Promise<PredictionResult> {
        const jobPredictions = this.post<PredictionResult>(ApiRoutes.Predictions.GetPrediction, predictionRequest);
        return jobPredictions;
    }

    async GetListOfReadyAccounts(): Promise<Account[]> {
        const accounts = this.get<Account[]>(ApiRoutes.Predictions.GetListOfReadyAccount);
        return accounts;
    }

    // async CreateExcelFromPredictions(excelRequest: CreatePredictionsExcelRequest): Promise<CreatePredictionsExcelResponse> {
    //     const excelResponse = this.post<CreatePredictionsExcelResponse>(ApiRoutes.Predictions.CreateExcelFromPredictions, excelRequest);
    //     return excelResponse;
    // }

    async AuditPredictions(auditRequest: AuditPredictionRequest): Promise<string> {
        const result = this.post<string>(ApiRoutes.Predictions.AuditPredictions, auditRequest);
        return result;
    }

    async PredictionBatchUpload(userFilterResult: UserFilterResult): Promise<BaseResponse> {
        const response = await this.post<BaseResponse>(ApiRoutes.Predictions.PredictionBatchUpload, userFilterResult);
        return response;
    }

    /**
     * @param batchId the BatchID
     * @returns excel URL as string
     */
    async CreateExcelFromBatch(batchRequest: CreateExcelFromBatchRequest): Promise<CreatePredictionsExcelResponse> {
        const excelURL = await this.post<CreatePredictionsExcelResponse>(ApiRoutes.Predictions.CreateExcelFromBatch, batchRequest);
        return excelURL;
    }

}

export default new PredictionService(ServiceUrls.Prediction);