import { PredictionSettings } from "../../../../stores/prediction/datamodels/PredictionSettings";
import { NumbersHelper } from '../../../../services/helpers/NumbersHelper';


export class SettingsRules {
    /**
     *
     */
    constructor(
    ) {

    }

    isValidInput(
        cvr: string,
        cvh: string,
        duration: number,
        predictionSettings: PredictionSettings,
        defaultPredictionSettings: PredictionSettings
        ): [boolean, "error" | "warning" | "info", string] {
        if (NumbersHelper.isNumber(cvh) == false && cvh !== "") {
            return [false, "error", "Please make sure app. to hires ratio is a valid number"];

        }
        if (NumbersHelper.isNumber(cvr) == false && cvr !== "") {
            return [false, "error", "Please make sure clicks to app. ratio is a valid number"]
        }

        if (
            (Number(cvr) || undefined) === predictionSettings.cvr &&
            (Number(cvh) || undefined) === predictionSettings.cvh &&
            duration === predictionSettings.predictionDuration
        ) {
            return [false, "warning", "You didn't change any setting"]
        }

        if ((Number(cvh) > 100 || Number(cvh) < 0) || (Number(cvr) > 100 || Number(cvr) < 0)) {
            return [false, "error", "Make sure cvh and cpa are valid numbers between 0 to 100"]
        }
        return [true, "info", ""];


    }
}