
import React from 'react';
import { Routes } from '../constants/Routes';
import LoginPage from './../components/login-page/LoginPage';
import InputPage from './../components/input-page/InputPage';
import PredictionPage from './../components/predictions-page/PredictionPage';
import AdminPreloadPage from '../components/admin-preload-page/AdminPreloadPage';

export class RouteConfiguration {
    /**
     *
     */
    constructor(
        public path: string,
        public reactComponent: React.ComponentClass,
        public isAuthorizedRoute: boolean
    ) { }
}

export class RouteSetup {
    public static GetRouteConfiguration() {
        return [
            new RouteConfiguration(Routes.Login, LoginPage, false),
            new RouteConfiguration(Routes.InputPage, InputPage, true),
            new RouteConfiguration(Routes.PredictionPage, PredictionPage, true),
            new RouteConfiguration(Routes.AdminPreload, AdminPreloadPage, true),
        ]
    }
}