import { useEffect, useContext } from 'react';
import { RootStoreContext } from './../stores/RootStoreContext';
import { LocalStorageService } from './../services/LocalStorageService';
import { UserAuthorizationResponse } from './../models/authorization/UserAuthorizationResponse';
import { LocalStorageKeys } from '../constants/LocalStorageKeys';
import { SessionStorageService } from './../services/SessionStorageService';
import { SessionStorageKeys } from '../constants/SessionStorageKeys';

const useLoadUserIfSaved = () => {
    const {userStore, uiStore} = useContext(RootStoreContext);

    useEffect(() => {
        const authorizationResponse = LocalStorageService.getItem<UserAuthorizationResponse>(LocalStorageKeys.AuthorizedResponse);
        if(authorizationResponse) {
            userStore.SetUserAndToken(authorizationResponse);
        }

        uiStore.setApplicationLoader(false);
    }, [])
}

export default useLoadUserIfSaved