
export type predictionDuration = 30 | 14 | 7;

export class PredictionSettings {
    /**
     *
     */
    constructor(
        public predictionDuration: predictionDuration,
        public cvr?: number,
        public cvh?: number

    ) {
    }
}