import { Box, Paper } from "@material-ui/core";
import { ReactElement, useContext, useState } from "react";
import { useStyles } from "./AdminPreloadPage.styles";
import { message } from "antd";
import useSetCurrentPage from "../../hooks/useSetCurrentPage";
import { PreloadRequest } from "../../models/prediction/PreloadRequest";
import { Account } from "../../models/Account";
import useOnLoadAsync from "../../hooks/useOnLoadAsync";
import AccountService from "../../services/account/AccountService";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { AdminPreloadPageBuilder } from "./AdminPreloadPage.builder";
import useKeyboardListener from "../../hooks/useKeyboardListener";
import { Guid } from "../../services/Guid";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "../../constants/Routes";
import { withRouter } from "react-router";
import { AdminPreloadRule, InputType } from "./AdminPreloadRule";
import { IInputValidator } from "../../services/input-validator/IInputValidator";
import { EGrantType } from "../../models/users/User";

interface Props extends RouteComponentProps {}

const inputDefaultValues = {
    margin: "50",
    cvh: "5",
};

function AdminPreloadPage(props: Props): ReactElement {
    const { history } = props;
    const { userStore, predictionStore } = useContext(RootStoreContext);
    const [margin, setMargin] = useState<string>(inputDefaultValues.margin);
    const [cvh, setCvh] = useState<string>(inputDefaultValues.cvh);
    const [loading, setLoading] = useState<boolean>(false);
    const [account, setAccount] = useState<Account | null>(null);
    const [accountList, setAccountList] = useState<Account[]>([]);
    const [areExpansionsExcluded, setAreExpansionsExcluded] = useState(true);
    const classes = useStyles();

    const handleSubmit = async () => {
        const ruleValidator: IInputValidator<InputType> =
            new AdminPreloadRule();
        const validatorResponse = ruleValidator.isValid({
            account,
            margin,
            cvh,
        });
        if (!validatorResponse.isValid) {
            ruleValidator.notifyError(validatorResponse);
            return;
        }

        const preloadRequest: PreloadRequest = new PreloadRequest(
            account!.accountId,
            Number(margin),
            Number(cvh),
            userStore.user!.userId,
            areExpansionsExcluded
        );

        try {
            setLoading(true);
            const result = await predictionStore.PreloadAccount(preloadRequest);
            message.success(result);
            history.push(Routes.InputPage);
        } catch (error: any) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setAccount(null);
        setMargin(inputDefaultValues.margin);
        setCvh(inputDefaultValues.cvh);
    };

    /* Hooks
     * ################################################
     */

    useSetCurrentPage();

    useOnLoadAsync(async () => {
        const accounts = await userStore.MakeAuthorizedHttpRequest(async () => {
            return await AccountService.GetAllAccounts();
        });
        if (!accounts) {
            message.error(ErrorMessages.TokenExpired);
            return;
        }
        setAccountList(accounts);
    });

    useKeyboardListener(Guid.NewGuid(), ["alt", "1"], () => {
        history.push(Routes.InputPage);
    });

    const builder = new AdminPreloadPageBuilder(classes);
    return (
        <Box className={classes.background}>
            <Box className={classes.paperWrapper}>
                <Paper elevation={3} className={classes.paper}>
                    <main>
                        {/* {builder.Title("Admin Page - Preparation")} */}
                        {builder.AccountAutocompleteInput(
                            accountList,
                            account,
                            setAccount,
                            handleSubmit
                        )}
                        {userStore.user!.eGrantType === EGrantType.Admin &&
                            builder.Input(
                                "Margin (%)",
                                margin,
                                setMargin,
                                handleSubmit
                            )}

                        {builder.Input(
                            "Applicants to hire conversion rate (%)",
                            cvh,
                            setCvh,
                            handleSubmit
                        )}

                        {builder.CheckBox(
                            "Exclude existing job expansion variants from report",
                            areExpansionsExcluded,
                            setAreExpansionsExcluded
                        )}

                        {builder.Buttons(loading, handleSubmit, handleClear)}
                    </main>
                </Paper>
            </Box>
        </Box>
    );
}

export default withRouter(observer(AdminPreloadPage));
