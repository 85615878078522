import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { Routes } from "../constants/Routes";
import { Page } from "../stores/UIStore";
import { RootStoreContext } from './../stores/RootStoreContext';

const useSetCurrentPage = () => {
    const {uiStore} = useContext(RootStoreContext);
    const location = useLocation();

    switch(location.pathname) {
        case Routes.InputPage:
            uiStore.setCurrentPage(Page.InputPage);
            break;
        case Routes.PredictionPage:
            uiStore.setCurrentPage(Page.PredictionPage);
            break;
        case Routes.AdminPreload:
            uiStore.setCurrentPage(Page.AdminPreload);
            break;
        default:
            uiStore.setCurrentPage(Page.InputPage);
            break;
    }
};

export default useSetCurrentPage;
