import { NumbersHelper } from '../../../services/helpers/NumbersHelper';



export class PredictionGridModel {
    /**
     *
     */
    public jobId: number;
    public jobTitle: string;
    public externalApplyUrl: string;
    public city: string;
    public state: string;
    public category: string;
    public clickRange: string;
    public demandFactor: string;
    public supplyFactor: string;
    public competition: string;
    public budget: number;
    public applicants: number;
    public conversionRate: number;
    public cpc: number;
    public clicks: number;
    public cpa: number;
    public location: string;
    public lat: number;
    public long: number;

    // Range fields
    // public budgetLowRange: number;
    // public cpaLowRange: number;

    constructor(
        jobId: number,
        jobTitle: string,
        externalApplyUrl: string,
        city: string,
        state: string,
        category: string,
        clickRange: string,
        demandFactor: string,
        supplyFactor: string,
        competition: string,
        budget: number,
        applicants: number,
        conversionRate: number,
        lat: number,
        long: number,
    ) {
        this.jobId = jobId;
        this.jobTitle = jobTitle;
        this.externalApplyUrl = externalApplyUrl;
        this.city = city;
        this.state = state;
        this.category = category;
        this.clickRange = clickRange;
        this.demandFactor = demandFactor;
        this.supplyFactor = supplyFactor;
        this.competition = competition;
        this.lat = lat;
        this.long = long;

        const clicks = applicants / conversionRate;
        const cpc = budget / clicks;
        const cpa = budget / applicants;
        this.conversionRate = NumbersHelper.toFixed((NumbersHelper.toFixed(conversionRate, 4) * 100), 2);
        this.cpc = NumbersHelper.toFixed((cpc), 2);
        this.clicks = NumbersHelper.toFixed(clicks, 0);
        this.applicants = NumbersHelper.toFixed(applicants, 0);
        this.cpa = NumbersHelper.toFixed(cpa, 2);
        this.budget = NumbersHelper.toFixed(budget, 2);
        this.location = `${city}, ${state}`;

        // Range fields
        // this.budgetLowRange = NumbersHelper.toFixed(budget * 0.8, 2);
        // this.cpaLowRange = NumbersHelper.toFixed(budget * 0.8, 2);
    }
}
