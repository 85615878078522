import { useEffect } from "react";

const useOnLoadAsync = (callback: any) => {
    useEffect(() => {
        (async () => {
            await callback();
        })();
    }, []);
};

export default useOnLoadAsync;
