import { NumbersHelper } from '../../../services/helpers/NumbersHelper';



export class PredictionSummary {
    /**
     * All the values are in string to format them with commas. Example 1,245
     */
    public clicks: string;
    public applicants: string;
    public conversionRate: string;
    public budget: string;
    public averageCpa: string;
    public averageCpc: string;
    public jobsAmount: string;
    public hires: string;
    public cvh: string;
    public averageCph: string;

    /*
    Low range
    */
   public budgetLowRange: string;
   public averageCpcLowRange: string;
   public averageCpaLowRange: string;
   public averageCphLowRange: string;

    constructor(
        clicks: number,
        applicants: number,
        cvr: number,
        budget: number,
        averageCpa: number,
        averageCpc: number,
        jobsAmount: number,
        hires: number,
        cvh: number,
        averageCph: number

    ) {
        this.clicks = NumbersHelper.numberWithCommas(clicks.toFixed(0));
        this.applicants = NumbersHelper.numberWithCommas(applicants.toFixed(0));
        this.conversionRate = `${(NumbersHelper.toFixed(cvr, 4) * 100).toFixed(2)}`;
        this.budget = NumbersHelper.numberWithCommas(budget.toFixed(0));
        this.averageCpa = NumbersHelper.numberWithCommas(averageCpa.toFixed(2));
        this.averageCpc = NumbersHelper.numberWithCommas(averageCpc.toFixed(2));
        this.jobsAmount = NumbersHelper.numberWithCommas(jobsAmount);
        this.hires = NumbersHelper.numberWithCommas(hires.toFixed(0));
        this.cvh = `${cvh.toFixed(2)}`
        this.averageCph = NumbersHelper.numberWithCommas(averageCph.toFixed(2));


        // Low ranges
        const lowRangeFactor = 0.8;
        this.budgetLowRange = NumbersHelper.numberWithCommas((budget * lowRangeFactor).toFixed(0));
        this.averageCpcLowRange = NumbersHelper.numberWithCommas((averageCpc * lowRangeFactor).toFixed(2));
        this.averageCpaLowRange = NumbersHelper.numberWithCommas((averageCpa * lowRangeFactor).toFixed(2));
        this.averageCphLowRange = NumbersHelper.numberWithCommas((averageCph * lowRangeFactor).toFixed(2));
        
    }
}
