import { action, makeObservable, observable } from "mobx";
import { Nullable } from '../types/Nullable';


export enum Page {
    InputPage,
    PredictionPage,
    AdminPreload
}

export class UIStore {

    /**
     * Props
     */
    @observable
    public currentPage: Nullable<Page>;

    @observable
    public applicationLoader: boolean = true;


    /**
     * Ctor
     */
    constructor() {
        makeObservable(this);
    }


    @action
    setCurrentPage(page: Page): void {
        this.currentPage = page;
    }

    @action
    setApplicationLoader(state: boolean): void {
        this.applicationLoader = state;
    }

}