import { JSXElementConstructor, ReactElement } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";
import { Account } from "../../models/Account";
import Loader from "../common/feedback/loader/Loader";
import AutoCompleteInput from "../common/inputs/auto-complete-input/AutoCompleteInput";
import Input from "../common/inputs/input/Input";

export class AdminPreloadPageBuilder {
    /**
     *
     */
    constructor(public classes: any) {}

    public Title(title: string) {
        return <h1 className={this.classes.pprTitle}>{title}</h1>;
    }

    public AccountAutocompleteInput(
        accountList: Account[],
        account: Account | null,
        setAccount: (account: Account | null) => void,
        handleSubmit: () => void
    ) {
        return (
            <Box my={2}>
                <h3 className={this.classes.subTitle}>Select account</h3>
                {accountList.length === 0 ? (
                    <Box display="flex" justifyContent="center">
                        <Loader style={{ width: 70, height: 70 }} />
                    </Box>
                ) : (
                    <AutoCompleteInput
                        placeholder="Account Name"
                        data={accountList}
                        getOptionCb={(v) => v?.accountName ?? ""}
                        onChange={(v) => setAccount(v)}
                        onEnterPress={handleSubmit}
                        filterOptions={(accounts, state) =>
                            accounts.filter(
                                (account) =>
                                    account?.accountName
                                        .toLowerCase()
                                        .includes(
                                            state.inputValue.toLowerCase()
                                        ) ||
                                    account?.accountId.toString() ===
                                        state.inputValue
                            )
                        }
                        value={account}
                    />
                )}
            </Box>
        );
    }

    public Input(
        title: string,
        margin: string,
        setMargin: (value: string) => void,
        handleSubmit: () => void
    ) {
        return (
            <Box mt={4}>
                <h3 className={this.classes.subTitle}>{title}</h3>
                <Input
                    placeholder="%"
                    value={margin}
                    valueSetter={setMargin}
                    onEnterPress={handleSubmit}
                />
            </Box>
        );
    }

    public CheckBox(
        label: string,
        areExpansionsExcluded: boolean,
        setAreExpansionsExcluded: (state: boolean) => void
    ) {
        return (
            <Box mt={4}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={areExpansionsExcluded}
                            onChange={(event) => {
                                setAreExpansionsExcluded(event.target.checked);
                            }}
                            color="primary"
                        />
                    }
                    label={label}
                />
            </Box>
        );
    }

    public Buttons(
        loading: boolean,
        handleSubmit: () => void,
        handleClear: () => void
    ) {
        if (loading) {
            return (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Loader
                        style={{
                            width: 75,
                            height: 75,
                            transform: "translateY(24px)",
                        }}
                    />
                </Box>
            );
        }
        return (
            <>
                <Box mt={8} width="100%">
                    <Button
                        onClick={handleSubmit}
                        fullWidth
                        color="primary"
                        variant="contained">
                        Save changes
                    </Button>
                </Box>

                <Box data-about="clear-button" textAlign="center" mt={4}>
                    <Button
                        className={this.classes.clearButton}
                        onClick={handleClear}
                        color="secondary">
                        Clear form
                    </Button>
                </Box>
            </>
        );
    }
}
