import React, { CSSProperties, ReactElement } from "react";
import pandoLoader from "./PL-Loader-dark-blue-small.gif";

interface Props {
    style?: CSSProperties;
    className?: string;
}

function Loader(props: Props): ReactElement {
    const { style, className } = props;
    return (
        <img
            style={{ objectFit: "contain", ...style }}
            className={className}
            src={pandoLoader}
        />
    );
}

export default Loader;
