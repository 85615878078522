import React from "react";
import { MapPoint } from "../../../../../stores/prediction/datamodels/MapPoint";
import ReactMapGL, { Popup } from "react-map-gl";
import {
    PopupTitle,
    PopupContentRow,
    CreateContentRows,
} from "./LocationPopup.factory";
import { observer } from "mobx-react-lite";

interface Props {
    selectedJob: MapPoint | null;
    setSelectedJob: (job: MapPoint | null) => void;
}

const LocationPopup = (props: Props) => {
    const { selectedJob, setSelectedJob } = props;
    if (!selectedJob) return <></>;

    return (
        <Popup
            latitude={selectedJob.latitude}
            longitude={selectedJob.longitude}
            onClose={() => {
                setSelectedJob(null);
            }}>
            <div className="jobs-map">
                {PopupTitle(
                    selectedJob.location,
                    selectedJob.competitionCategory
                )}
                {CreateContentRows(selectedJob)}
            </div>
        </Popup>
    );
};

export default observer(LocationPopup);
